/* Text Styles */
.text-styles {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #CED0D4;
}

/* Page Div Styles */
/* Note: you'll need to replace 'path_to_image' with your actual image path. */
.page-div-styles {
  background-image: url('../../assets/images/pianoBackground.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
}

/* Filter Div Styles */
.filter-div-styles {
  width: 100%;
  height: 100%;
  backdrop-filter: brightness(15%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-height: 768px) {
  .filter-div-styles {
    justify-content: flex-start;
  }
}

/* Sign In Div Styles */
.sign-in-div-styles {
  background-color: #050B13;
  width: 740px;
  flex-shrink: 0;
  max-width: 80%;
  max-height: 80%;
  border-radius: 12px;
  justify-content: center;
  position: relative;
  z-index: 10;

  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding-bottom: 20px;
}
@media (max-height: 768px) {
  .sign-in-div-styles {
    gap: 20px;
    margin-top: 50px;
    max-height: 86%;
    max-width: 100%;
    min-height: 65%;
    width: 100%;
  }
}

::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.desc-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  max-width: 590px;
}

h3 {
  color: #CED0D4;
  text-align: center;
  /* H3 */
  font-family: Lato;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.34px;
}
@media (max-height: 768px) {
  h3 {
    font-size: 26px;
    margin: 14px 12px 6px 12px;
  }
}

p {
  color: #FFF;
  text-align: center;

  /* Body 1 */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
}
@media (max-height: 768px) {
  p {
    font-size: 14px;
  }
}

.plan-scroll-container {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 32px;
}
@media (max-height: 768px) {
  .plan-scroll-container {
    overflow: scroll;
    gap: 20px;
  }
}

.plan-container {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
/* @media (max-height: 768px) {
  .plan-container {
    display: flex;
    flex-direction: column;
  }
} */
.plan {
  position: relative;
  display: flex;
  /* width: 224px; */
  width: 180px;
  height: 248px;
  /* padding: 40px 40px; */
  padding: 36px 40px 20px 40px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  border-radius: 8px;
  border: 1px solid var(--neutrals-500, #5B646E);
  background: var(--neutrals-900, #050B13);
}
@media (max-height: 768px) {
  .plan {
    padding: 28px 20px;
  }
}
.plan-desc {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
@media (max-height: 768px) {
  .plan-desc {
    gap: 4px;
  }
}

.discount-percent {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  width: 80.552px;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 0px 8px;
  background: #6F87F1;
}
.discount-percent-text {
  color: #FFF;
  text-align: center;

  /* Subtitle 1 */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.16px;
  margin: 0px;
}

.plan-text-desc {
  color: var(--secondary-green-05, #65AF83);
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  margin: 0;
}
@media (max-height: 768px) {
  .plan-text-desc {
    font-size: 14px;
  }
}
.plan-text-price {
  color: var(--neutrals-white, #FFF);
  text-align: center;

  /* H3 */
  font-family: Lato;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.34px;
  margin: 0;
}
@media (max-height: 768px) {
  .plan-text-price {
    font-size: 26px;
  }
}

.plan-text-time {
  color: var(--neutrals-white, #FFF);
  text-align: center;

  /* Body 1 */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
  margin: 0;
}
@media (max-height: 768px) {
  .plan-text-time {
    font-size: 14px;
  }
}

.plan-text-equivalent {
  color: var(--neutrals-white, #ADB1B7);
  text-align: center;
  /* Body 1 */
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
  margin: 3px 0px;
}
@media (max-height: 768px) {
  .plan-text-equivalent {
    font-size: 12px;
  }
}

.lesson-check {
  width: 160px;
  display: flex;
  align-items: center;
  gap: 3px;
  text-align: left;
  justify-content: flex-start;
}

.green-check-img {
  width: 22px;
}

.MuiButton-root.submit-button {
  

  display: flex;
  /* transform: rotate(90deg); */
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 40px;
  background: #CA7C62;

  color: #050B13;
  text-align: center;

  /* Button */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.5px;
  text-transform: uppercase;

}

.trial-submit-button {
  width: 315px;
}

.submit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.MuiButton-root.discount-code {
  color: var(--primary-300-main, #CA7C62);
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.16px;
  text-decoration-line: underline;
}

/* Back Container Styles */
.back-container-styles {
  position: absolute;
  left: 22px;
  top: 22px;
}

.back-container-styles:hover {
  background: rgba(222,144,118,.5);
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50%;
  box-shadow: 0px 0px 5px 5px rgb(222,144,118,.5);
}

.discount-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}
@media (max-height: 768px) {
  .discount-container {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
}
.code-field-container {
  position: relative;
  /* background-color: red; */
}
.MuiButton-root.discount-code {
  color: var(--primary-300-main, #CA7C62);
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.16px;
  text-decoration-line: underline;
}


.MuiButton-root.discount-button {
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 40px;
  border: 2px solid #CA7C62;

  color: #CA7C62;
  text-align: center;

  /* Button */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.5px;
  text-transform: uppercase;
}



.discount-input .MuiInputBase-input {
  position: relative;
  /* styles for the input */
  width: 245px;
  /* height: 36px; */
  padding: 14px 20px;
  color: #ADB1B7;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
}

.discount-input .MuiOutlinedInput-root {
  /* styles for the border and border-radius */
  border-radius: 80px;
  /* border: 1px solid #5B646E; */
}
/* Style the border when NOT focused */
.discount-input-neutral .MuiOutlinedInput-root:not(.Mui-focused) {
  border: 1px solid #5B646E;
}


.discount-input .MuiInputLabel-root {
  color: #ADB1B7;
  font-feature-settings: 'clig' off, 'liga' off;

  /* Input Label */
  font-family: Lato;
  /* font-size: 12px; */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.15px;
}


.valid-discount .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #81C69C;
}
.valid-discount .MuiInputLabel-root {
  color: #81C69C;
}
.valid-discount .MuiInputBase-input {
  color: #81C69C;
}

.plan-container-text .MuiInputLabel-root {
  color: #fff;
  font-weight: 700;
  margin-left: 32px;
}


.text-disabled .MuiInputLabel-root {
  color: #fff;
  margin-left: 42px;
}

.text-billing-settings .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 1px solid #ADB1B7;
  box-sizing: border-box;
}

.trial-text-billing-settings .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 1px solid #81C69C;
}

.plan-container-text-selected .MuiInputLabel-root {
  color: #100D09;
}
.plan-container-text-selected .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline{
  border: none;
  color: #100D09;
}

.valid-discount .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #81C69C;
}
.valid-discount .MuiInputLabel-root.Mui-focused {
  color: #81C69C;
}
.valid-discount .MuiInputBase-input:focus {
  color: #81C69C;
}


.invalid-discount .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #E7C4B9;
}
.invalid-discount .MuiInputLabel-root {
  color: #E7C4B9;
}
.invalid-discount .MuiInputBase-input {
  color: #E7C4B9;
}
.invalid-discount .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #E7C4B9;
}
.invalid-discount .MuiInputLabel-root.Mui-focused {
  color: #E7C4B9;
}
.invalid-discount .MuiInputBase-input:focus {
  color: #E7C4B9;
}


/* Style the border when focused */
.discount-input-neutral .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #5B646E;
}
/* Style the label text when focused */
.discount-input-neutral .MuiInputLabel-root.Mui-focused {
  color: #ADB1B7;
}
/* Style the input text when focused */
.discount-input-neutral .MuiInputBase-input:focus {
  color: #ADB1B7;
}

.plan-check {
  position: absolute;
  top: 14px;
  left: 14px;
}

.MuiLink-root.link {
  color: #6F87F1;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.16px;
  text-decoration-line: underline;
}

.code-container {
  position: absolute;
  display: flex;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  /* z-index: 200; */

  /* margin: auto; */

  top: 16px;
  right: 14px;
  /* background-color: red; */

  border-radius: 24px;
  background: #81C69C;

  color:#2E5B3E;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.15px;
}



.code-container.invalid {
  border-radius: 24px;
  background: #E7C4B9;
}

.trial-logout-container {
  position: absolute;
  top: 20px;
  right: 22px;
  color: var(--neutrals-200, #ADB1B7);
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
  margin-bottom: 3px;
  z-index: 2;
}

.trial-logout-container:hover {
  background: rgba(222,144,118,.5);
  cursor: pointer;
  transition: 0.3s;
  border-radius: 24px;
  box-shadow: 0px 0px 5px 5px rgb(222,144,118,.5);
}
