body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.chatbox-scroll-container {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none;  /* for Firefox */
}

.chatbox-scroll-container::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}


@keyframes pulse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

/* Play container styles (can move to separate css */
.play-container-text-container {
  margin: 0;
  display: flex;
  align-items: flex-start; 
  text-align: left;
}

.play-container-text-description {
  font-style: normal; 
  font-weight: 700;
  font-size: 18px;
}

@media (max-width: 1200px) {
  .play-container-text-description {
    min-width: 150px;
    font-size: 14px;
  }
}

.play-container-text {
  margin: 0px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 15px; 
  letter-spacing: 0.01em;
  color: #CED0D4;
  line-height: 133%;
}

@media (max-width: 1200px) {
  .play-container-text {
    font-size: 12px;
  }
}

@keyframes error-page-rotation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(20deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}


.left-position-1 { left: -1px; }
.left-position-2 { left: 58px; }
.left-position-3 { left: 117px; }
.left-position-4 { left: 176px; }

/* @media (max-width: 1200px), (max-height: 900px) {
  .left-position-2 {
    left: 38px;
  }
  .left-position-3 {
    left: 77px;
  }
  .left-position-4 {
    left: 116px;
  }
} */

.first-chevron-styles {
  width: 0;
  height: 0;
  display: inline;
  border-style: solid;
  border-width: 80px 0 80px 54px;
  border-color: transparent transparent transparent #1D2F44;
  position: absolute;
  left: -1px;
}
/* @media (max-width: 1200px), (max-height: 900px) {
  .first-chevron-styles {
    border-width: 49px 0 49px 33px;
  }
} */



/* For Blocking Screens */

.logo-image-container-style {
  position: absolute;
  top: -35px;
  height: 70px;
}

.logo-image-style {
  max-height: 100%;
}

.museflow-container-styles {
  background-color: black;
  max-width: 80%;
  max-height: 80%;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10;
}
@media (max-width: 1200px), (max-height: 900px) {
  .museflow-container-styles {
    margin-bottom: 100px;
  }
}

.blocking-container-styles {
  max-width: 1000px;
  height: 450px;
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  padding: 80px 120px 40px 120px;
}
@media (max-width: 1200px), (max-height: 900px) {
  .blocking-container-styles {
    /* height: 400px; */
    font-size: 26px;
    padding: 60px 25px 25px 25px;
  }
}

.blocking-container-flex {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: Lato;
}

.p-blocking-container {
  margin-top: 50px;
  font-size: 18px;
  line-height: 133%;
  text-align: left;
}
@media (max-width: 1200px), (max-height: 900px) {
  .p-blocking-container {
    margin-top: 30px;
    /* font-size: 16px;
    line-height: 20px; */
    /* text-align: left; */
  }
}

.p-blocking-container-small {
  font-size: 14px;
}
@media (max-width: 1200px), (max-height: 900px) {
  .p-blocking-container-small  {
    font-size: 12px;
    /* text-align: left; */
  }
}

.signup-form-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px 0px 20px;
  gap: 20px;
}
@media (max-height: 768px) {
  .signup-form-container {
    margin-top: 0px;
    gap: 14px;
  }
}

#osmdCanvasPage1 {
  position: absolute !important
}

/* .leaflet-container:-webkit-full-screen {
  width: 100% !important;
  height: 100% !important;
} */