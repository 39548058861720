
.header-styles {
  height:  160px;
  min-height:  160px;
  display: flex;
  flex-direction: row;
  position: relative;
}
@media (max-width: 1200px) or (max-height: 900px) {
  .header-styles {
    height:  100px;
    min-height:  100px;
  }
}

.header-container-styles {
  background-color: black;
  width: 300px;
}
@media (max-width: 1200px) or (max-height: 900px) {
  .header-container-styles {
    width: 180px;
  }
}

.tutorial-left-position-1 { left: -1px; }
.tutorial-left-position-2 { left: 58px; }
.tutorial-left-position-3 { left: 117px; }
.tutorial-left-position-4 { left: 176px; }

@media (max-width: 1200px), (max-height: 900px) {
  .tutorial-left-position-2 {
    left: 38px;
  }
  .tutorial-left-position-3 {
    left: 77px;
  }
  .tutorial-left-position-4 {
    left: 116px;
  }
}

.tutorial-first-chevron-styles {
  width: 0;
  height: 0;
  display: inline;
  border-style: solid;
  border-width: 80px 0 80px 54px;
  border-color: transparent transparent transparent #1D2F44;
  position: absolute;
  left: -1px;
}
@media (max-width: 1200px), (max-height: 900px) {
  .tutorial-first-chevron-styles {
    border-width: 49px 0 49px 33px;
  }
}