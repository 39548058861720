
.roadmap-img-styles {
  height: 400px;
}
@media (max-width: 1200px) or (max-height: 900px) {
  .roadmap-img-styles {
    height: 260px;
  }
}

