.billing-subtitle {
  text-align: left;
  color: var(--neutrals-white, #FFF);

  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
  letter-spacing: 0.2px;
}