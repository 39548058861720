
.container-styles-1 {
  max-width: 85vw;
  min-width: 1200px;
  width: 1200px;
  min-height: 170px;
  border: 3px solid #525969;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px 20px 20px;
  margin: 0px 30px 0px 30px;
}
@media (max-width: 1200px) or (max-height: 900px) {
  .container-styles-1 {
    padding: 20px 15px 12px 15px;
    min-width: unset;
  }
}

.container-styles-1.level-0 {
  min-width: 600px;
  width: 600px;
}

.container-styles-2 {
  display: flex; 
  justify-content: flex-start; 
  align-items: center; 
  margin-bottom: 10px;
  height: 110px;
  max-width: 100%;
  min-width: 300px;
}

.container-styles-3 {
  display: flex; 
  flex-direction: column;  
  align-items: flex-start; 
  justify-content: center; 
  margin-right: 20px;
}

.play-container-styles {
  width: 110px;
  height: 110px;
  border-radius: 50%;
}
@media (max-width: 1200px) or (max-height: 900px) {
  .play-container-styles {
    width: 78px;
    height: 78px;
  }
}

.play-container-styles:hover {
  background: rgba(202,124,98,.25);
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50%;
  box-shadow: 0px 0px 5px 5px rgb(202,124,98,.5);
}


.play-circle-styles {
  box-sizing: border-box;
  width: 110px;
  height: 110px;
  border: 3px solid #CA7C62;
  border-radius: 50%;
}
@media (max-width: 1200px) or (max-height: 900px) {
  .play-circle-styles {
    width: 78px;
    height: 78px;
    left: 17px;
    bottom: 45px;
  }
}


.poly-styles {
  display: flex;  
  position: relative;  
  isolation: isolate;  
  flex-direction: row;  
  height: 55px;
  left: 31px;
  bottom: 82px;
  padding: 0px;
}
@media (max-width: 1200px) or (max-height: 900px) {
  .poly-styles {
    height: 39px;  
    left: 22px;
    bottom: 58px;
  }
}
