.hidden {
  animation: slide-out 1s ease-in-out both;
}

.visible {
  animation: slide-in 1s ease-in-out both;
}

@keyframes slide-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(100px);
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fullscreen-invert {
  filter: invert(1);
}
