
.roadmap-container-styles {
  height: 510px;
  width: 100%;
  position: relative;
}
@media (max-width: 1200px) or (max-height: 900px) {
  .roadmap-container-styles {
    height: 330px;
  }
}

