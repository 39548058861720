.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: co
  
  lumn;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pulsating-circle {
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  width: 15px;
  height: 15px;
}
.pulsating-circle:before {
  content: "";
  position: relative;
  display: block;
  width: 300%;
  height: 300%;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -100%;
  border-radius: 45px;
  opacity: 0;
  -webkit-animation: pulse-ring .75s cubic-bezier(0.215, 0.61, 0.355, 1) 1;
          animation: pulse-ring .75s cubic-bezier(0.215, 0.61, 0.355, 1) 1;
}

.pulsating-circle.correct-green:before {
  background-color: #469f6a;
}

.pulsating-circle.correct-blue:before {
  background-color: #0072B2;
}

.pulsating-circle.correct-white:before {
  background-color: #D9D9D9;
}

.pulsating-circle.semi-error-yellow:before {
  background-color: #aebb1a;
}

.pulsating-circle.semi-error-orange:before {
  background-color: #E69F00;
}

.pulsating-circle.semi-error-grey:before {
  background-color: #7F7F7F;
}

.pulsating-circle.error-red:before {
  background-color: #eb3459;
}

.pulsating-circle.error-brown:before {
  background-color: #D55E00;
}

.pulsating-circle.error-black:before {
  background-color: #404040;
}

@-webkit-keyframes pulse-ring {
  0% {
    opacity: 1;
    transform: scale(0.33);
  }
  80%, 100% {
    opacity: 0;
  }
}

@keyframes pulse-ring {
  0% {
    opacity: 1;
    transform: scale(0.33);
  }
  80%, 100% {
    opacity: 0;
  }
}