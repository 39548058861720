

.welcome-container {
  padding: 0 8px;
  margin: 0;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.welcome-container::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}
.welcome-container::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 10px;
}

.welcome-container::-webkit-scrollbar-thumb:window-inactive {
  background-color: grey;
  border-radius: 10px;
}

.title-welcome {
  color: #CED0D4;
  text-align: center;
  font-family: Lato;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.34px;

  margin: 10px 0px 0px 0px;
}
@media (max-height: 768px) {
  .title-welcome {
    font-size: 26px;
  }
}

.p-welcome-body {
  text-align: left;
  margin: 0px 20px;
  line-height: 133%;
}

.p-connect-midi {
  text-align: center;
  margin: 0px 20px;
}

.later-link {
  color: #CA7C62;
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.16px;
}

.connect-button-container {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.sound-output-container {
  display: flex;
  margin-top: 40px;
  margin-bottom: 10px;
}
@media (max-height: 768px) {
  .sound-output-container {
    margin-top: 10px;
    margin-bottom: 4px;
  }
}

.sound-output-selections {
  border-radius: 8px;
  width: 220px;
  padding: 32px 32px;
}
@media (max-height: 768px) {
  .sound-output-selections {
    width: 160px;
  }
}